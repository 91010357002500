import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import { AgendaTemplate, IFormData, Segment } from 'types/Agendas'

export interface AgendasState extends ApiState {
  getAgendaTemplate: (templateId: string) => Promise<AgendaTemplate[]>
  getAgendaTemplates: () => Promise<AgendaTemplate[]>
  createAgenda: (segments: IFormData[]) => Promise<void>
  updateAgenda: (segments: IFormData[]) => Promise<void>
  getAgenda: (taskId: string) => Promise<Segment[]>
  deleteAgenda: (taskId: string) => Promise<Segment[]>
  getAgendas: () => Promise<Segment[]>
}

export const createAgendasSlice: StateCreator<AgendasState> = (set, get) => ({
  getAgendaTemplate: async (templateId) => {
    const response = await get().api!('GET', `/agenda/templates/${templateId}`)
    return response.data
  },
  getAgendaTemplates: async () => {
    const response = await get().api!('GET', '/agenda/templates')
    return response.data
  },
  createAgenda: async (segments) => {
    const response = await get().api!('POST', '/agenda/', { segments })
    return response.data
  },
  getAgenda: async (taskID) => {
    const response = await get().api!('GET', `/agenda/${taskID}`)
    return response.data
  },
  updateAgenda: async (segments) => {
    const response = await get().api!('PUT', '/agenda/', { segments })
    return response.data
  },
  deleteAgenda: async (taskID) => {
    const response = await get().api!('DELETE', `/agenda/${taskID}/`)
    return response.data
  },
  getAgendas: async () => {
    const response = await get().api!('GET', '/agenda/')
    return response.data
  },
})
