import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined'
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined'
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined'
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import EventNoteIcon from '@mui/icons-material/EventNote'

const icons = {
  AssignmentTurnedInOutlinedIcon: AssignmentTurnedInOutlinedIcon,
  ChatOutlinedIcon: ChatOutlinedIcon,
  BarChartOutlinedIcon: BarChartOutlinedIcon,
  ChecklistOutlinedIcon: ChecklistOutlinedIcon,
  NewspaperIcon: NewspaperIcon,
  CheckOutlinedIcon: CheckOutlinedIcon,
  ChecklistRtlOutlinedIcon: ChecklistRtlOutlinedIcon,
  ConnectWithoutContactOutlinedIcon: ConnectWithoutContactOutlinedIcon,
  ContentPasteGoOutlinedIcon: ContentPasteGoOutlinedIcon,
  LibraryAddCheckOutlinedIcon: LibraryAddCheckOutlinedIcon,
  NoteAltOutlinedIcon: NoteAltOutlinedIcon,
  GroupsOutlinedIcon: GroupsOutlinedIcon,
  EventNoteIcon: EventNoteIcon,
}

export const getIcon = (icon?: string) => {
  if (icon && icons.hasOwnProperty(icon)) {
    return icons[icon as keyof typeof icons]
  }
  return TimelapseOutlinedIcon
}
