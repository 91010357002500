import React from 'react'
import {
  Typography,
  Stack,
  ListItemText,
  ListItemIcon,
  ListItem,
  ListItemButton,
} from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import type { Segment } from 'types/Agendas'
import SvgIcon from '@mui/material/SvgIcon'
import { getIcon } from 'utils/iconUtils'
import { camelize } from 'humps'

type SegmentIcon = {
  rollCall: string
  reviewLevel: string
  metrics: string
  headlines: string
  issues: string
  toDo: string
  meetingScore: string
}

type SegmentItemProps = {
  item: Segment
  draggable?: boolean
  selected?: boolean
  onClick?: () => void
}

const segmentIcon: SegmentIcon = {
  rollCall: 'AssignmentTurnedInOutlinedIcon',
  reviewLevel: 'GroupsOutlinedIcon',
  metrics: 'BarChartOutlinedIcon',
  headlines: 'ChecklistRtlOutlinedIcon',
  issues: 'ContentPasteGoOutlinedIcon',
  toDo: 'LibraryAddCheckOutlinedIcon',
  meetingScore: 'MeetingSurvey',
}

const SegmentItem = ({
  item,
  draggable,
  selected,
  onClick,
}: SegmentItemProps) => {
  const segmentName = camelize(item.name)
  return (
    <ListItem
      key={item.id}
      disablePadding
      secondaryAction={
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          px={2}
        >
          <Typography variant="body1" gutterBottom>
            {item.timeSpent} mins
          </Typography>
        </Stack>
      }
    >
      <ListItemButton
        disableGutters
        dense
        selected={selected}
        onClick={onClick}
      >
        <Stack direction="row" px={2} py={1}>
          {draggable && (
            <ListItemIcon sx={{ minWidth: 32 }}>
              <DragIndicatorIcon />
            </ListItemIcon>
          )}
          <ListItemIcon sx={{ minWidth: 32 }}>
            <SvgIcon
              component={
                item?.icon
                  ? getIcon(item.icon)
                  : getIcon(segmentIcon[segmentName as keyof SegmentIcon])
              }
              inheritViewBox
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item.title}
                </Typography>
                <Typography
                  ml={0.5}
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {item.description}
                </Typography>
              </>
            }
          />
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}

export default SegmentItem
