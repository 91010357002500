import {
  DeploymentUnitOutlined,
  DownOutlined,
  ShareAltOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { $generateHtmlFromNodes } from '@lexical/html'
import AddSharpIcon from '@mui/icons-material/AddSharp'
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp'
import {
  App,
  Badge,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  SelectProps,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import DateSelectorModal from 'components/DateSelectorModal'
import { DeleteTaskButton } from 'components/DeleteTasksDialog'
import DueDateDropdown from 'components/DueDateDropdown'
import EmailSelector from 'components/EmailSelector'
import FlagCheckbox from 'components/FlagCheckbox'
import RoutineDropdown from 'components/RoutineDropdown'
import SchedulingDropDown from 'components/SchedulingDropdown'
import WYSIWYG from 'components/WYSIWYG'
import { ContentPastePluginProps } from 'components/WYSIWYG/plugins/ContentPastePlugin'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { useDebounceValue } from 'hooks/useDebounce'
import useStore from 'hooks/useStore'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import { decode } from 'html-entities'
import { produce } from 'immer'
import { EditorState, LexicalEditor } from 'lexical'
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { useTaskFlowViewer } from 'sections/TaskFlowViewer'
import { SaveAction } from 'services/Settings.slice'
import { TaskMode, TaskType } from 'services/Tasks.slice'
import { Contact } from 'types/Contacts'
import { CustomField, CustomFieldValue } from 'types/CustomFields'
import { FlexibleTime } from 'types/FlexibleTime'
import { Status } from 'types/Status'
import {
  DelegationType,
  FormTaskType,
  PendingAssignee,
  ScheduledTask,
  TaskEditFormForwardRefType,
  TaskItem,
  TaskPriority,
  TaskPriorityGroup,
  TaskStatusId,
} from 'types/Tasks'
import { Userpilot } from 'userpilot'
import { customEvents, publish } from 'utils/event'
import { getTaskTreeFromLexical } from 'utils/lexical'
import {
  PermissionRoleIDs,
  removeNotAllowedFields,
  TaskActions,
  TaskFields,
  TaskInternals,
} from 'utils/permissions'
import { getCheckListItemsCount } from 'utils/richText'
import {
  AssigneeInvitationStatus,
  checkIfAssigneeHasEditorPermissions,
  convertTaskStatusToCurriculumStatus,
  generateSingleListContacts,
  getDateFromTitle,
  getGroupNameWithOwnerIfNeeded,
  getPrivateGroup,
  getSingleListAssignees,
  hasKey,
  isEmailIncludedInList,
  isInstanceBeforeToday,
  isInstanceOfSubtaskOfRoutine,
  isRoutineDefinitionSchedule,
  isRoutineInstanceSchedule,
  isScheduledForToday,
  removeUncheckedCheckboxes,
} from 'utils/taskUtils'
import { DelegationDrawer } from '../DelegationDrawer'
import SelectTag from '../DelegationDrawer/SelectTag'
import FloatingContainer from '../FloatingContainer'
import SubItems from '../SubItems'
import AnimatedContainer from './AnimatedContainer'
import InviteActions from './InviteActions'
import SaveActions from './SaveActions'
import SelectDropdown from './SelectDropdown'
import styles from './TaskEditForm.module.scss'
import { AgendaTemplate } from 'types/Agendas'
import { generateAgenda } from 'utils/agendaUtils'

const { Text, Title, Link } = Typography

type Action = {
  type: SaveAction
}

type InitialState = {
  actionText: string
  background: string
  color: string
  key: string
}

type TaskEditFormProps = {
  closeModal: () => void
  isFlagged: boolean
  toggleFlagged: (value: boolean) => void
  subItems: Partial<TaskType>[]
  toggleIsSubtaskVisible: (value: boolean) => void
  isSubtaskVisible: boolean
  isSubItemDirty: boolean
  setIsSubItemDirty: (value: boolean) => void
  setSubItems: (value: Array<Partial<TaskType>>) => void
  onInvite: () => void
  onAddCompany: () => void
  onAddContact: () => void
  handleDeleteTask: (event: React.MouseEvent<HTMLElement> | undefined) => void
  handleCancelDelete: () => void
  isRoutineDefinition: boolean
  setIsRoutineDefinition: (value: boolean) => void
  isDocked?: boolean
  customFields: CustomField | null | undefined
  setCustomFields: Dispatch<SetStateAction<CustomField | null | undefined>>
  customData: CustomFieldValue | null | undefined
  isCustomFieldTabNotDirty: () => boolean
  isCustomFieldFormValid: () => Promise<boolean>
  goToCustomFieldTab: () => void
  saveCustomData: (taskId: string) => void
  isInvitation?: boolean
}

type Options = {
  id?: string
  label: string
  value: number
  key: number
}

const saveActionReducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case SaveAction.SAVE_AND_NEW:
      return {
        ...state,
        actionText: 'actions.save-and-new',
        background: 'var(--business-category-color)',
        color: '#FFF',
        key: SaveAction.SAVE_AND_NEW,
      }
    case SaveAction.SAVE_AND_CLOSE:
      return {
        ...state,
        actionText: 'actions.save-and-close',
        background: 'var(--completed-color)',
        color: '#FFF',
        key: SaveAction.SAVE_AND_CLOSE,
      }
    case SaveAction.SAVE:
      return {
        ...state,
        actionText: 'actions.save',
        background: 'var(--need-help-color)',
        color: 'var(--white)',
        key: SaveAction.SAVE,
      }
    default:
      return state
  }
}

const TaskEditForm = forwardRef<TaskEditFormForwardRefType, TaskEditFormProps>(
  (props, ref) => {
    const {
      closeModal,
      isFlagged,
      toggleFlagged,
      subItems,
      setSubItems,
      isSubItemDirty,
      setIsSubItemDirty,
      toggleIsSubtaskVisible,
      onInvite,
      onAddCompany,
      onAddContact,
      isRoutineDefinition,
      setIsRoutineDefinition,
      isDocked,
      customFields,
      setCustomFields,
      customData,
      isCustomFieldTabNotDirty,
      isCustomFieldFormValid,
      goToCustomFieldTab,
      saveCustomData,
      isInvitation = false,
    } = props

    useImperativeHandle(ref, () => ({
      async submitFormAndComplete(defaultValues) {
        if (defaultValues) {
          for (const property in defaultValues) {
            if (!form.getFieldValue(property)) {
              if (hasKey(defaultValues, property)) {
                form.setFieldValue(property, defaultValues[property])
              }
            }
          }
        }

        try {
          await form.validateFields()
          const values = form.getFieldsValue()
          submitTask(values, true)
          dispatch({ type: SaveAction.SAVE_AND_CLOSE })
          return true
        } catch (error) {
          return false
        }
      },
      checkIsFormDirty() {
        return isFormDirty()
      },
    }))

    const app = App.useApp()
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const origin = window.location.origin

    const {
      initialValues = {},
      isDrawerOpen,
      isOpen,
      toggleDrawer,
      setInitialValues,
      hasUpdatePermissionOnTask: hasFieldPermission,
      editorMode,
      setEditorMode,
      setSelectedEmails,
      initialAssignees,
      setInitialAssignees,
    } = useTaskEditor()
    const {
      selectedEmails,
      updateSelectedEmails,
      updateIsIndividualModeSelected,
      isIndividualModeSelected,
      isFloatButtonVisible,
      isFloatButtonTemporarilyHidden,
    } = useTaskEditorStore((state) => state)

    const titleInputRef = useRef<InputRef>(null)
    const editorRef = useRef<LexicalEditor>(null)
    const contentPasteRef = useRef<ContentPastePluginProps>(null)

    const { isTaskFlowViewerVisible, openTaskFlowViewer } = useTaskFlowViewer()

    const [routineIsOpen, setRoutineIsOpen] = useState(false)
    const [taskMode, setTaskMode] = useState(TaskMode.ACTIVE)
    const [status, setStatus] = useState(Status.IDLE)
    const [companiesOptions, setCompaniesOptions] = useState<Options[]>([])
    const [contactsOptions, setContactsOptions] = useState<Contact[]>([])

    const [listContacts, setListContacts] = useState<PendingAssignee[]>([])
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const [hasSubItems, setHasSubItems] = useState(false)

    const [children, setChildren] = useState<Partial<TaskItem>[] | []>([])

    const [editorState, setEditorState] = useDebounceValue<
      EditorState | undefined
    >(undefined, 1000)
    const [lexicalSubtasks, setLexicalSubtasks] = useState<TaskType[]>([])

    const [isAdvanced, setisAdvanced] = useState(
      editorMode === 'edit' ? true : isDocked ? true : false,
    )
    const [assigneeHasEditorPermissions, setAssigneeHasEditorPermissions] =
      useState(true)
    const [curriculumInstanceId, setCurriculumInstanceId] = useState<
      number | undefined
    >(undefined)
    const [minDate, setMinDate] = useState('')
    const [showDateSelector, setShowDateSelector] = useState(false)
    const [modalPromiseResolve, setModalPromiseResolve] = useState<
      ((value: string | PromiseLike<string>) => void) | null
    >(null)

    const resetCollaboratorInvites = useStore(
      (state) => state.resetCollaboratorInvites,
    )

    const createAgenda = useStore((state) => state.createAgenda)

    const initialState: InitialState = {
      actionText: 'actions.save-and-new',
      background: 'var(--business-category-color)',
      color: 'var(--white)',
      key: SaveAction.SAVE_AND_NEW,
    }

    const [saveAction, dispatch] = useReducer(saveActionReducer, initialState)

    const createTask = useStore((state) => state.createTask)
    const updateTask = useStore((state) => state.updateTask)
    const getTaskById = useStore((state) => state.getTaskById)
    const taskRolePermissions = useStore((state) => state.taskRolePermissions)
    const taskPriorityGroups = useStore((state) => state.priorityGroups)
    const statusList = useStore((state) => state.tasks?.statusList)
    const groups = useStore((state) => state.tasks?.groups)
    const setLastModifiedTasks = useStore((state) => state.setLastModifiedTasks)
    const taskLevels = useStore((state) => state.taskLevels)
    const getCompanies = useStore((state) => state.getCompanies)
    const getContacts = useStore((state) => state.getContacts)
    const allGroups = useStore((state) => state.allGroups)
    const contacts = useStore((state) => state.contacts)
    const selectedGroups = useStore((state) => state.selectedGroups)
    const getDescendants = useStore((state) => state.getDescendants)
    const user = useStore((state) => state.user?.data)
    const favoriteGroup = useStore((state) => state.favoriteGroup)
    const updateCurriculumLesson = useStore(
      (state) => state.updateCurriculumLesson,
    )
    const userId = user?.id
    const email = user?.email
    const fullName = `${user?.firstName} ${user?.lastName}`
    const defaultAssignee = useMemo(
      () =>
        ({
          email: email,
          name: fullName,
        } as PendingAssignee),
      [email, fullName],
    )

    const [scheduledTask, setScheduledTask] = useState<
      Partial<ScheduledTask> | undefined | null
    >(initialValues.scheduledTask)
    const isRoutineInstance = isRoutineInstanceSchedule(initialValues)
    const isSubtaskOfRoutine = isInstanceOfSubtaskOfRoutine(initialValues)
    const isRecurring =
      isRoutineInstance || isSubtaskOfRoutine || isRoutineDefinition
    const isRecurringStyle =
      isRoutineDefinition || isRoutineInstance
        ? { color: 'var(--warning-color)' }
        : {}

    const [flexibleTime, setFlexibleTime] = useState<FlexibleTime | undefined>(
      FlexibleTime.BEGINNING,
    )
    const [agenda, setAgenda] = useState<AgendaTemplate | undefined>(undefined)
    const [tmpFormValues, setTmpFormValues] = useState<FormTaskType>()
    const [isRoutinePopconfirmOpen, setIsRoutinePopconfirmOpen] =
      useState(false)
    const [checkItemsCount, setCheckItemsCount] = useDebounceValue(0, 1000)
    const [checkedItems, setCheckedItems] = useDebounceValue(0, 1000)

    const [scheduledPriorityGroup, setScheduledPriorityGroup] =
      useState<TaskPriorityGroup>(TaskPriorityGroup.Now)

    const isTemplate = initialValues.mode === TaskMode.TEMPLATE

    const initialGroupId = useMemo(() => {
      return (
        initialValues.group?.id ||
        (selectedGroups?.length === 1 ? selectedGroups[0] : undefined) ||
        favoriteGroup ||
        getPrivateGroup(groups, user?.id)
      )
    }, [
      initialValues.group?.id,
      favoriteGroup,
      groups,
      user?.id,
      selectedGroups,
    ])

    const handleRecurringChange = (value?: Partial<ScheduledTask> | null) => {
      updateScheduledTask(value)
    }

    const openPlanViewer = useCallback(
      (values?: Partial<TaskItem>) => {
        closeModal()
        openTaskFlowViewer(values)
        setIsSubItemDirty(false)
      },
      [closeModal, openTaskFlowViewer, setIsSubItemDirty],
    )

    const updateScheduledTask = useCallback(
      (newValue?: Partial<ScheduledTask> | null) => {
        setScheduledTask(newValue)
        if (!isRoutineInstance && !isSubtaskOfRoutine) {
          setIsRoutineDefinition(isRoutineDefinitionSchedule(newValue))
        }
        if (newValue) {
          if (
            !isRoutineInstance &&
            !isSubtaskOfRoutine &&
            !isRoutineDefinitionSchedule(newValue)
          ) {
            if (!isScheduledForToday({ scheduledTask: newValue })) {
              form.setFieldsValue({
                priorityGroup: TaskPriorityGroup.Scheduled,
              })
              setScheduledPriorityGroup(TaskPriorityGroup.Scheduled)
            }
          }
        } else if (newValue === null) {
          form.setFieldsValue({
            priorityGroup: TaskPriorityGroup.Now,
          })
          setScheduledPriorityGroup(TaskPriorityGroup.Now)
        }
      },
      [form, isRoutineInstance, isSubtaskOfRoutine, setIsRoutineDefinition],
    )

    const isUpdate =
      typeof initialValues.isUpdate !== 'undefined'
        ? initialValues.isUpdate
        : isDocked ||
          Object.keys(initialValues).filter((item) => item !== 'id').length > 0

    const isParentTask = children?.length !== 0

    const isIssue = useMemo(() => {
      return initialValues?.priorityGroup?.id === 8
    }, [initialValues.priorityGroup])

    const levelOptions: SelectProps['options'] = [
      ...(taskLevels
        ? taskLevels.map((level) => {
            if (level.id === 8) {
              return {
                value: level.id,
                label: level.title,
                disabled: subItems.length > 0,
              }
            }

            return {
              value: level.id,
              label: level.title,
              disabled: false,
            }
          })
        : []),
      ...((initialValues?.level?.id || 0) > 8
        ? [
            {
              value: initialValues?.level?.id,
              label: t('my-today-page.base-tasks'),
              disabled: true,
            },
          ]
        : []),
    ]

    const isFormDirty = (ignoreStatusAndPriorityGroup?: boolean) => {
      const {
        title = '',
        level = { id: taskLevels?.find((level) => level.title === 'ToDo')?.id },
        status = {
          id: statusList?.find((item) => item.title === 'Not Started')?.id,
        },
        priorityGroup = { id: 0 },
        group = { id: initialGroupId },
        scheduledTask: initialScheduledTask = undefined,
        taskDetails,
      } = initialValues

      const initial = [
        title,
        level?.id,
        status?.id,
        priorityGroup?.id,
        group?.id,
      ]

      const assigneesOriginal = initialAssignees.map((as) => as.email)
      if (
        JSON.stringify(assigneesOriginal.sort()) !==
        JSON.stringify(selectedEmails.sort())
      ) {
        return true
      }

      if (initialScheduledTask) {
        initial.push(JSON.stringify(initialScheduledTask))
      }

      const formValues = form.getFieldsValue([
        'title',
        'level',
        'status',
        'priorityGroup',
        'group',
        'description',
      ])

      // Joaquin - March 21,2023
      // the initial and current arrays should always maintain the same order of
      // values to prevent this check from breaking
      // [title, level, status, priorityGroup, group, scheduledTask, taskDetails]

      const current = [
        formValues.title,
        formValues.level,
        formValues.status,
        formValues.priorityGroup,
        formValues.group,
      ]

      // Compare only the description text without the html tags
      if (taskDetails?.description) {
        const strippedHtml = decode(taskDetails?.description).replace(
          /<[^>]+>/g,
          '',
        )
        initial.push(strippedHtml.trim())
      } else {
        initial.push('')
      }
      // Push the scheduled task here to maintain the order of the array
      if (scheduledTask) {
        current.push(JSON.stringify(scheduledTask))
      }

      // Lexical adds a paragraph tag with a line break when clicking on the description (taskDetails) field
      // This is a workaround to avoid the form being dirty when the description is empty
      if (formValues?.description) {
        const strippedHtml = decode(formValues?.description).replace(
          /<[^>]+>/g,
          '',
        )
        current.push(strippedHtml.trim())
      } else {
        current.push('')
      }

      if (ignoreStatusAndPriorityGroup) {
        initial.splice(2, 2)
        current.splice(2, 2)
      }

      return !initial.every((value, index) => value === current[index])
    }

    const checkDirty = () => {
      if (isCustomFieldTabNotDirty() && !isFormDirty()) {
        closeModal()
        updateScheduledTask(undefined)
        setCheckItemsCount(0)
        setCheckedItems(0)
        setInitialValues(undefined)
      } else {
        setIsConfirmOpen(true)
      }
      setIsSubItemDirty(false)
    }

    const importSubtasks = (excludeCheckedItems?: boolean) => {
      if (!editorState) {
        return
      }
      setLexicalSubtasks(getTaskTreeFromLexical(editorState.toJSON()) || [])
      form.setFieldValue('importTasks', true)
      form.setFieldValue('excludeCompletedTasks', excludeCheckedItems)
      form
        .validateFields()
        .then(() => {
          if (excludeCheckedItems) {
            contentPasteRef.current?.removeCompletedOnes()
            editorState.read(() => {
              if (editorRef.current) {
                const htmlString = $generateHtmlFromNodes(
                  editorRef.current,
                  null,
                )
                form.setFieldValue(
                  'description',
                  removeUncheckedCheckboxes(htmlString),
                )
              }
            })
          }
          form.submit()
        })
        .catch(() => {
          form.setFieldValue('importTasks', false)
        })
    }

    const submitAndModifyAssociatedTask = () => {
      setIsRoutinePopconfirmOpen(false)
      if (isRoutineInstance) {
        submitTask({ ...tmpFormValues!, update_routine_definition: true })
      } else if (isRoutineDefinition) {
        submitTask({ ...tmpFormValues!, update_current_instance: true })
      } else {
        submitTask({ ...tmpFormValues! })
      }
    }

    const submitAndDoNotModifyOtherTask = () => {
      setIsRoutinePopconfirmOpen(false)
      submitTask({ ...tmpFormValues! })
    }

    const askUserBeforeSubmit = (values: FormTaskType) => {
      setTmpFormValues(values)
      if (isUpdate || (editorMode === 'edit' && !isTemplate)) {
        if (isRoutineInstance && isFormDirty(true)) {
          setIsRoutinePopconfirmOpen(true)
          return
        }
        if (isRoutineDefinition) {
          setIsRoutinePopconfirmOpen(true)
          return
        }
      }
      if (!isInvitation) submitTask(values)
    }

    const submitTask = async (
      values: FormTaskType,
      completeTaskAfterCreation: boolean = false,
    ) => {
      if (isSubItemDirty) {
        app.notification.warning({
          message: t('task-form.unsaved-subtask-warning', { ns: 'validation' }),
        })

        return
      }

      if (values.status === TaskStatusId.DONE) {
        const isValidCustomField = await isCustomFieldFormValid()
        if (!isValidCustomField) {
          app.notification.warning({
            message: t('task-form.custom-field-completed', {
              ns: 'validation',
            }),
          })
          return
        }
      }

      if (selectedEmails.length === 0) {
        app.notification.warning({
          message: t('task-form.assignees-required', {
            ns: 'validation',
          }),
        })
        return
      }

      const flag = isFlagged ? TaskPriority.URGENT : TaskPriority.NORMAL
      const parent = isUpdate ? undefined : initialValues.parent?.id
      const assigneeRoleId = assigneeHasEditorPermissions
        ? PermissionRoleIDs.EDITOR
        : PermissionRoleIDs.STANDARD
      const strippedHtml = decode(values?.description).replace(/<[^>]+>/g, '')
      const isSingleDelegation = selectedEmails.length === 1
      const assignees = selectedEmails.map((email) => ({
        email,
        role: assigneeRoleId,
        assignToAll: isSingleDelegation,
      }))
      const delegationType = isSingleDelegation
        ? DelegationType.SINGLE
        : isIndividualModeSelected
        ? DelegationType.INDIVIDUAL_PARENT
        : DelegationType.SHARED

      const mainTask = produce(
        {
          mode: taskMode,
          title: values.title,
          level: values.level,
          status: values.status,
          priorityGroup: isRoutineDefinition
            ? TaskPriorityGroup.RoutineDefinition
            : values.priorityGroup,
          parent,
          group: values.group,
          details: {
            flag,
            description: strippedHtml ? values?.description : '',
          },
          scheduledTask: scheduledTask,
          taskAssignees: assignees,
          update_routine_definition:
            values.update_routine_definition || undefined,
          update_current_instance: values.update_current_instance || undefined,
          delegationType: delegationType,
        } as unknown as TaskType,
        (draft) => {
          if (taskMode === TaskMode.ACTIVE) {
            draft.activatedAt = dayjs().format()
          }

          if (values.dueDate) {
            draft.dueAt = dayjs(values.dueDate).toISOString()
          } else {
            draft.dueAt = null
          }

          if (!isUpdate && !isAdvanced) {
            const simpleTaskFields = [
              'title',
              'mode',
              'activatedAt',
              'dueAt',
              'scheduledTask',
              'taskAssignees',
            ]
            // remove all fields that are not required for a simple task
            Object.keys(draft).forEach((key) => {
              if (!simpleTaskFields.includes(key)) {
                delete draft[key as keyof TaskType]
              }
            })
            // Joaquin - March 26,2023
            // Creating simple tasks require the following fields:
            // title, mode, priorityGroup, level, group, status
            draft.priorityGroup = TaskPriorityGroup.Now
            draft.level = taskLevels?.find(
              (level) => level.title === 'ToDo',
            )?.id
            draft.group = initialGroupId
            draft.status = statusList?.find(
              (status) => status.title === 'Not Started',
            )?.id

            if (draft.scheduledTask) {
              draft.priorityGroup = scheduledPriorityGroup
            }

            if (isRoutineDefinition) {
              draft.priorityGroup = TaskPriorityGroup.RoutineDefinition
            }
          }

          if (taskMode === TaskMode.TEMPLATE) {
            delete draft['taskAssignees']
          }
          if (isUpdate || editorMode === 'edit') {
            // remove all fields that are not allowed to be updated
            removeNotAllowedFields(
              draft,
              initialValues?.roles,
              taskRolePermissions,
            )
          }
        },
      )

      const hasPermissionToAddChild = isUpdate
        ? hasFieldPermission(TaskActions.ADD_CHILDREN)
        : true
      let subTasks: TaskType[] = []
      const importTasks = 'importTasks' in values && values.importTasks
      if (hasPermissionToAddChild && importTasks) {
        if (editorState) {
          subTasks = [...lexicalSubtasks]
          if (
            'excludeCompletedTasks' in values &&
            values.excludeCompletedTasks
          ) {
            subTasks = subTasks.filter((subtask) => !subtask.completionAt)
          }
        }
      } else if (hasPermissionToAddChild && subItems.length > 0) {
        subTasks = subItems.map<TaskType>((item) => ({
          title: item.title!,
          mode: taskMode,
          level: values.level + 1, // always a level above the parent
          priorityGroup: item.priorityGroup || values.priorityGroup,
          status: values.status,
          group: values.group,
          taskAssignees: taskMode === TaskMode.TEMPLATE ? undefined : assignees,
        }))
      }

      const { scheduling } = values
      // If task is associated with a curriculum lesson, get
      // completion date if not completed on date it was assigned
      if (
        mainTask.status &&
        mainTask.status === TaskStatusId.DONE &&
        curriculumInstanceId &&
        mainTask.title
      ) {
        if (isInstanceBeforeToday(mainTask.title)) {
          const minDate = getDateFromTitle(mainTask.title)
          setMinDate(minDate)
          const dateInput = await getUserDateInput()
          mainTask.completionAt = dateInput
        }
      }

      if (mainTask.status && mainTask.status === TaskStatusId.DONE) {
        mainTask.taskPosition = 'bottom'
      } else if (mainTask?.details?.flag === TaskPriority.URGENT) {
        mainTask.taskPosition = 'top'
      }

      if (editorMode === 'edit') {
        return updateTask(
          {
            taskId: initialValues?.id || '',
            data: mainTask,
            subTasks,
          },
          userId!,
        )
          .then((taskEdited: TaskItem[]) => {
            setLastModifiedTasks(taskEdited)
            publish(customEvents.UpdateTask, taskEdited)

            // If task is associated with a curriculum, update the status of the curriculum lessons
            if (taskEdited[0].status?.id) {
              const curriculumStatus = convertTaskStatusToCurriculumStatus(
                taskEdited[0].status?.id,
              )
              if (curriculumInstanceId && curriculumStatus) {
                updateCurriculumLesson(curriculumInstanceId, {
                  status: curriculumStatus,
                })
              }
            }

            app.notification.success({
              message: t('new-task-form.update-success'),
            })

            if (importTasks && subTasks.length) {
              openPlanViewer(taskEdited[0])
            }

            if (saveAction.key === SaveAction.SAVE) {
              const task = taskEdited[0]
              setInitialValues(task)
              const pendingAssignees = getSingleListAssignees(task)
              updateSelectedEmails(pendingAssignees.map((value) => value.email))
              setInitialAssignees(pendingAssignees)
              setChildren(task.children || [])
              setHasSubItems(!!task.children?.length)
              return
            }
            setInitialValues(undefined)
            resetCollaboratorInvites()
            updateSelectedEmails([])
            setInitialAssignees([])
            setCheckItemsCount(0)
            setCheckedItems(0)
          })
          .then(() => {
            if (saveAction.key === SaveAction.SAVE_AND_CLOSE) {
              closeModal()
            }

            if (saveAction.key === SaveAction.SAVE_AND_NEW) {
              setEditorMode('create')
              // jQN - temp workaround to reset the form after saving
              setTimeout(() => {
                form.resetFields()
              }, 500)
              // jQN - temp workaround to focus the title field saving
              setTimeout(() => {
                if (titleInputRef && titleInputRef.current) {
                  titleInputRef.current.focus()
                }
              }, 1000)
            }
          })
          .catch((error) => {
            const message =
              error?.response?.data?.fallback_message || error.message
            app.notification.error({
              message,
            })
          })
          .finally(() => {
            if (titleInputRef && titleInputRef.current) {
              titleInputRef.current.focus()
            }
          })
      }

      createTask(
        {
          data: mainTask,
          subTasks,
          scheduling,
          customFields,
          customValues: customData,
        },
        user!.id,
      )
        .then((taskCreated: TaskItem[]) => {
          publish(customEvents.SaveNewTask, taskCreated)
          saveCustomData(taskCreated?.[0]?.id)
          if (agenda) {
            const agendaPayload = generateAgenda(
              agenda,
              {
                id: taskCreated?.[0]?.id,
                title: taskCreated?.[0]?.title,
              },
              taskCreated?.[0]?.scheduledTask?.pendingAttendees || [],
            )
            createAgenda(agendaPayload)
          }
          if (completeTaskAfterCreation) {
            const updatedProperties: TaskType = {
              status: TaskStatusId.DONE,
              completionAt: new Date().toISOString(),
              mode: TaskMode.COMPLETED,
            }
            updateTask(
              {
                taskId: taskCreated?.[0]?.id,
                data: updatedProperties,
              },
              user!.id,
            )
          }

          updateScheduledTask(undefined)
          setSubItems([])
          setCustomFields(undefined)

          app.notification.success({
            message: t('new-task-form.create-success'),
          })

          if (importTasks && subTasks.length) {
            openPlanViewer(taskCreated[0])
          }

          if (saveAction.key === SaveAction.SAVE) {
            const task = taskCreated[0]
            setInitialValues(task)
            setEditorMode('edit')
            const pendingAssignees = getSingleListAssignees(task)
            updateSelectedEmails(pendingAssignees.map((value) => value.email))
            setInitialAssignees(pendingAssignees)
            setChildren(task.children || [])
            setHasSubItems(!!task.children?.length)
            return
          }

          if (saveAction.key === SaveAction.SAVE_AND_CLOSE) {
            closeModal()
          }

          if (saveAction.key === SaveAction.SAVE_AND_NEW) {
            resetCollaboratorInvites()
            updateSelectedEmails([defaultAssignee.email])
            setInitialAssignees([defaultAssignee])
            updateIsIndividualModeSelected(false)
            form.resetFields()
            setTimeout(() => {
              if (titleInputRef && titleInputRef.current) {
                titleInputRef.current.focus()
              }
            }, 500)
          }

          setCheckItemsCount(0)
          setCheckedItems(0)
          setScheduledPriorityGroup(TaskPriorityGroup.Now)
        })
        .catch((error) => {
          setStatus(Status.ERROR)
          const message =
            error?.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
        })
    }

    useEffect(() => {
      const list = generateSingleListContacts(contacts, allGroups)
      setListContacts(list)
    }, [contacts, allGroups])

    const groupOptions: SelectProps['options'] = groups
      ?.filter(
        (group) =>
          group.groupMembers?.find((gm) => gm.user === user?.id)?.status !==
            'archived' && group.status !== 'archived',
      )
      .map((group) => ({
        value: group.id,
        label: getGroupNameWithOwnerIfNeeded({ group } as TaskItem, groups),
      }))

    const statusOptions: SelectProps['options'] = statusList
      ?.filter((status) => isUpdate || status.id !== TaskStatusId.DONE)
      .map((status) => ({
        value: status.id,
        label: status.title,
      }))

    const exists = groups?.some((item) => item.id === initialValues?.group?.id)

    if (isUpdate) {
      if (!exists) {
        groupOptions?.push({
          value: initialValues.group?.id,
          label:
            initialValues.group?.owner?.name +
            "'s " +
            initialValues.group?.title,
        })
      }
    }

    const onEditorChange = (
      editorState: EditorState,
      editor: LexicalEditor,
    ) => {
      setEditorState(editorState)
      if (editorState.isEmpty()) {
        return
      }

      const editorStateJSON = editorState.toJSON()
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor, null)
        form.setFieldValue('description', htmlString)
      })

      const checkListItems = getCheckListItemsCount(
        JSON.stringify(editorStateJSON),
      )

      if (checkListItems) {
        setCheckedItems(checkListItems.done)
        setCheckItemsCount(checkListItems.count)
      } else {
        setCheckedItems(0)
        setCheckItemsCount(0)
      }
    }

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(
          `${origin}/task/${initialValues.id}`,
        )
        app.modal.success({
          title: 'Link copied to clipboard!',
          content: (
            <Link
              href={`${origin}/task/${initialValues.id}`}
              target="_blank"
              ellipsis
            >{`${origin}/task/${initialValues.id}`}</Link>
          ),
        })
      } catch (error) {
        app.notification.error({
          message: 'Failed to copy!',
        })
      }
    }

    const getTitleLocaleEntry = () => {
      if (isRoutineDefinition) {
        return 'new-task-form.routine-definition'
      }
      if (isTemplate) {
        return 'new-task-form.edit-template'
      }
      if (editorMode === 'edit' || isUpdate) {
        return 'new-task-form.edit-task'
      }
      return 'new-task-form.create-task'
    }

    useEffect(() => {
      // Set default assignee on task creation
      if (!isUpdate) {
        setInitialAssignees([defaultAssignee])
        updateSelectedEmails([defaultAssignee.email])
        setSelectedEmails([defaultAssignee.email])
      }
    }, [
      defaultAssignee,
      initialValues.id,
      isUpdate,
      setSelectedEmails,
      setInitialAssignees,
      updateSelectedEmails,
    ])

    useEffect(() => {
      if (editorMode === 'create') {
        if (titleInputRef && titleInputRef.current) {
          titleInputRef.current.focus()
        }
      }
      if (editorMode === 'edit' && initialValues?.id && !isDocked) {
        // Force edit task action to be save and new
        dispatch({ type: SaveAction.SAVE_AND_CLOSE })
        getTaskById(initialValues?.id)
          .then((task) => {
            const { isCustomFieldTemplate, showChatAtOpening } = initialValues
            setInitialValues({
              ...task,
              isCustomFieldTemplate,
              isUpdate,
              showChatAtOpening,
            })
            if (
              task.mode === 'template' ||
              isCustomFieldTemplate ||
              showChatAtOpening
            ) {
              // jQN - July 23 2024 force templates to render
              // the correct values
              // Note: this needs to be updated if a field
              // is added to the form until this is refactored
              form.setFieldsValue({
                title: task.title,
                level: task.level?.id,
                priorityGroup: task?.priorityGroup?.id || 0,
                group: task.group?.id,
                status: task.status?.id,
                description: task.taskDetails?.description || null,
                dueDate: task.dueAt || '',
              })
            }

            // jQN - July 19 2024 - TODO: this needs refactoring due to being confusing
            // The task mode should only use mode draft or active and template and completed
            // should be handled differently.
            // at the moment we are choosing to keep the mode as active if the task is completed
            const mode =
              task.mode === 'draft'
                ? TaskMode.DRAFT
                : task.mode === 'template'
                ? TaskMode.TEMPLATE
                : TaskMode.ACTIVE

            setTaskMode(mode)

            updateIsIndividualModeSelected(
              task.delegationType === DelegationType.INDIVIDUAL_PARENT,
            )
            setFlexibleTime(
              initialValues?.scheduledTask?.flexibleTime ||
                FlexibleTime.BEGINNING,
            )

            const pendingAssignees = getSingleListAssignees(task)
            updateSelectedEmails(pendingAssignees.map((value) => value.email))
            setInitialAssignees(pendingAssignees)

            if (task?.taskDetails?.flag?.id === TaskPriority.URGENT) {
              toggleFlagged(true)
            }

            if (isCustomFieldTemplate) {
              setisAdvanced(true)
            }
            const { id, children = [], curriculumTaskInstance } = task

            if (children?.length > 0) {
              getDescendants(id, false).then((descendants) => {
                // Find child tasks in the descendants array
                const childTasks = descendants.filter(
                  (task) => task.id !== initialValues.id,
                )
                setChildren(childTasks)
                setHasSubItems(true)
              })
            }
            if (curriculumTaskInstance) {
              setCurriculumInstanceId(curriculumTaskInstance.id)
            }
          })
          .catch(() => {
            if (isDocked) {
              navigate('/tasks')
            }
          })
          .finally(() => {
            if (titleInputRef && titleInputRef.current) {
              titleInputRef.current.focus()
            }
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const { scheduledTask, children = [] } = initialValues

      if (scheduledTask) {
        updateScheduledTask(scheduledTask)
      } else {
        updateScheduledTask(undefined)
      }

      setAssigneeHasEditorPermissions(
        checkIfAssigneeHasEditorPermissions(initialValues),
      )
      if (
        children?.length > 0 &&
        children.some((child) => child.priorityGroup?.id !== 8)
      ) {
        setHasSubItems(true)
      } else {
        setHasSubItems(false)
      }
    }, [initialValues, updateScheduledTask])

    const getDrawerSpan = () => {
      if (isDrawerOpen) {
        return 8
      }
      return 0
    }

    const getFormSpan = () => {
      if (isDrawerOpen) {
        return 16
      }

      return 24
    }

    const getUserDateInput = () => {
      setShowDateSelector(true)
      return new Promise<string>((resolve) => {
        setModalPromiseResolve(() => resolve)
      })
    }

    const updateTaskLevel = (items: Array<Partial<TaskType>>) => {
      setSubItems(items)
      if (
        !isParentTask &&
        items.length > 0 &&
        form.getFieldValue('level') === 8
      ) {
        // If the Level is set to “Todo” and then the user adds “Sub Tasks”,
        // the Level should automatically then be changed to “Work Items”
        form.setFieldValue('level', taskLevels[6].id)
      }
    }

    const numberOfPendingAssignees = useMemo(
      () =>
        initialAssignees.filter(
          (assignee) =>
            (assignee.status === AssigneeInvitationStatus.PENDING ||
              assignee.status === AssigneeInvitationStatus.EXPIRED ||
              assignee.status === AssigneeInvitationStatus.REFUSED) &&
            selectedEmails.indexOf(assignee.email) !== -1,
        ).length,
      [initialAssignees, selectedEmails],
    )
    const isPendingAssignment = isEmailIncludedInList(
      initialValues?.pendingAssignees,
      user?.email,
    )
    const ActionButtons = isPendingAssignment ? (
      <InviteActions
        closeModal={closeModal}
        invitedBy={initialValues.user?.id as string}
        taskId={initialValues.id as string}
        email={user?.email!}
      />
    ) : (
      <SaveActions
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        closeModal={closeModal}
        updateScheduledTask={updateScheduledTask}
        resetFields={form.resetFields}
        setCheckItemsCount={setCheckItemsCount}
        setCheckedItems={setCheckedItems}
        isCustomFieldTabNotDirty={isCustomFieldTabNotDirty}
        goToCustomFieldTab={goToCustomFieldTab}
        checkDirty={checkDirty}
        isTemplate={isTemplate}
        isDocked={isDocked}
        taskMode={taskMode}
        setTaskMode={setTaskMode}
        isRoutineInstance={isRoutineInstance}
        isRoutinePopconfirmOpen={isRoutinePopconfirmOpen}
        submitAndModifyAssociatedTask={submitAndModifyAssociatedTask}
        submitAndDoNotModifyOtherTask={submitAndDoNotModifyOtherTask}
        setSubItems={setSubItems}
        status={status}
        dispatch={dispatch}
        saveAction={saveAction}
        submit={form.submit}
      />
    )

    return (
      <Row
        className={styles.taskEditForm}
        style={{ minHeight: isAdvanced ? 780 : 250 }}
      >
        <Col
          span={getDrawerSpan()}
          style={{
            backgroundColor: 'var(--background-color)',
            paddingBlock: 16,
            borderInlineEnd: '4px solid var(--border-color)',
          }}
        >
          {isDrawerOpen && (
            <DelegationDrawer
              isOpen={isDrawerOpen}
              isDocked={isDocked}
              initialValues={initialAssignees}
              onCancel={toggleDrawer}
              onInvite={onInvite}
              assigneeHasEditorPermissions={assigneeHasEditorPermissions}
              setAssigneeHasEditorPermissions={setAssigneeHasEditorPermissions}
            />
          )}
        </Col>
        <Col
          span={getFormSpan()}
          style={{
            paddingBlock: 16,
            paddingLeft: 24,
          }}
        >
          <Row>
            <Col span={12}>
              <Space>
                <Title level={5} style={{ paddingBottom: 4 }}>
                  {t(getTitleLocaleEntry())}
                  {taskMode === TaskMode.DRAFT ? (
                    <Text type="secondary" style={{ paddingLeft: 4 }}>
                      {` ~ ${t('new-task-form.draft')}`}
                    </Text>
                  ) : (
                    ''
                  )}
                </Title>
                {isUpdate && (
                  <ShareAltOutlined
                    onClick={copyToClipboard}
                    style={{
                      fontSize: 28,
                      marginBottom: '0.3em',
                      color: '#1677ff',
                    }}
                  />
                )}
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {isUpdate && (
                <DeleteTaskButton
                  isCustomDialog={isParentTask}
                  isMainTemplateTask={
                    initialValues.mode === TaskMode.TEMPLATE &&
                    !initialValues.parent
                  }
                  task={initialValues}
                  onExecute={closeModal}
                  disabled={!hasFieldPermission(TaskActions.DELETE_TASK)}
                />
              )}
            </Col>
          </Row>
          <Form
            form={form}
            key={initialValues?.id}
            layout="vertical"
            onFinish={askUserBeforeSubmit}
            initialValues={{
              title: initialValues.title || '',
              level:
                initialValues.level?.id ||
                taskLevels?.find((level) => level.title === 'ToDo')?.id,
              priorityGroup:
                initialValues?.priorityGroup?.id ||
                (taskPriorityGroups &&
                  taskPriorityGroups.length > 0 &&
                  taskPriorityGroups[0].id) ||
                0,
              group: initialGroupId,
              status:
                initialValues.status?.id ||
                statusList?.find((item) => item.title === 'Not Started')?.id,
              description: initialValues.taskDetails?.description || null,
              dueDate: initialValues.dueAt || '',
            }}
            onFieldsChange={(fields) => {
              const levelField = fields.find(
                (item) => (item.name as string[])[0] === 'level',
              )

              if (levelField) {
                toggleIsSubtaskVisible(levelField.value < 8)
              }
            }}
          >
            <Row gutter={16}>
              <Col span={isAdvanced ? 16 : 24}>
                <Form.Item
                  name="title"
                  label={t('new-task-form.name')}
                  style={{ flex: 1, marginBottom: 14 }}
                  rules={[
                    {
                      required: true,
                      message: t('task-form.title-required', {
                        ns: 'validation',
                      })!,
                    },
                  ]}
                >
                  <Input
                    ref={titleInputRef}
                    addonBefore={
                      <FlagCheckbox
                        value={isFlagged}
                        onChange={toggleFlagged}
                      />
                    }
                    type="text"
                    autoComplete="off"
                    disabled={!hasFieldPermission(TaskFields.TITLE)}
                  />
                </Form.Item>
              </Col>
              <Col span={isAdvanced ? 8 : 0} hidden={isAdvanced ? false : true}>
                <Form.Item
                  label={t('new-task-form.level')}
                  name="level"
                  style={{ flex: 1, marginBottom: 14 }}
                >
                  <Select
                    options={levelOptions}
                    disabled={!hasFieldPermission(TaskFields.LEVEL) || isIssue}
                  />
                </Form.Item>
              </Col>
            </Row>

            <AnimatedContainer animate={!isUpdate || !isDocked}>
              {isAdvanced && (
                <>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        required
                        label={t('new-task-form.status')}
                        name="status"
                        style={{ flex: 1, marginBottom: 14 }}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            validator: (_, value) => {
                              if (!value) return
                              const shouldEnterCustomData =
                                !!customFields?.customField?.filter(
                                  (customField) => customField.isRequired,
                                )?.length
                              const hasCustomData = !!customData?.customValue
                              if (
                                value === TaskStatusId.DONE &&
                                isUpdate &&
                                shouldEnterCustomData &&
                                !hasCustomData
                              ) {
                                return Promise.reject(
                                  new Error(
                                    t('task-form.custom-field-completed', {
                                      ns: 'validation',
                                    }),
                                  ),
                                )
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                      >
                        <Select
                          options={statusOptions}
                          disabled={!hasFieldPermission(TaskFields.STATUS)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        required
                        label={t('new-task-form.group')}
                        name="group"
                        style={{ flex: 1, marginBottom: 14 }}
                      >
                        <Select
                          options={groupOptions}
                          disabled={
                            !hasFieldPermission(TaskFields.GROUP) ||
                            isTemplate ||
                            isIssue ||
                            // Cannot reassign the group of a curriculum lesson outside of the curriculum
                            !!curriculumInstanceId
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        required
                        label={t('new-task-form.priority-group')}
                        name="priorityGroup"
                        style={{ flex: 1, marginBottom: 14 }}
                      >
                        <Select
                          options={taskPriorityGroups?.map((priorityGroup) => ({
                            value: priorityGroup.id || 0,
                            label: priorityGroup.title,
                            disabled: priorityGroup.id >= 4,
                          }))}
                          disabled={
                            isRoutineDefinition ||
                            !hasFieldPermission(TaskFields.PRIORITY_GROUP) ||
                            isIssue
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isTemplate ? (
                    <Row gutter={16} style={{ alignItems: 'center' }}>
                      <Text style={{ paddingBottom: 8, paddingLeft: 8 }}>
                        {t('new-task-form.assigned-to')}
                      </Text>
                      <Col span={24} style={{ marginBottom: 8, marginTop: 16 }}>
                        <Space direction="horizontal">
                          <Text strong style={{ paddingLeft: 8 }}>
                            {t('templates.role')}
                          </Text>
                          <Text style={{ paddingLeft: 8 }}>
                            {initialValues?.taskRole?.assigningRole}
                          </Text>
                        </Space>
                      </Col>
                      <Col span={24} style={{ marginBottom: 16, marginTop: 8 }}>
                        <Space direction="horizontal">
                          <Text strong style={{ paddingLeft: 8 }}>
                            {t('templates.user')}
                          </Text>
                          <Text style={{ paddingLeft: 8 }}>
                            {initialValues?.taskRole?.assignedEmail}
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row
                        gutter={16}
                        style={{ alignItems: 'center', marginBottom: 16 }}
                      >
                        <Tooltip
                          title={
                            isIndividualModeSelected
                              ? t('new-task-form.individual-delegation-tooltip')
                              : t('new-task-form.shared-delegation-tooltip')
                          }
                        >
                          <Text style={{ paddingBottom: 8, paddingLeft: 8 }}>
                            {isIndividualModeSelected
                              ? t('new-task-form.individual-delegation')
                              : t('new-task-form.shared-delegation')}
                          </Text>
                        </Tooltip>
                        <Col span={24} style={{ paddingRight: 0 }}>
                          <Row
                            gutter={[16, 16]}
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              flexFlow: 'nowrap',
                            }}
                          >
                            <Col
                              style={{ display: 'inline-block', paddingTop: 4 }}
                            >
                              <Button
                                icon={
                                  isDrawerOpen ? (
                                    <RemoveSharpIcon />
                                  ) : (
                                    <AddSharpIcon />
                                  )
                                }
                                onClick={toggleDrawer}
                                disabled={
                                  !hasFieldPermission(
                                    TaskFields.TASK_ASSIGNEES,
                                  ) || isTemplate
                                }
                              />
                            </Col>
                            <Col
                              flex="auto"
                              style={{
                                padding: 0,
                                display: 'inline-block',
                              }}
                            >
                              <Form.Item>
                                <EmailSelector
                                  initialValues={initialAssignees}
                                  userList={listContacts}
                                  disabled={
                                    !hasFieldPermission(
                                      TaskFields.TASK_ASSIGNEES,
                                    ) || isTemplate
                                  }
                                />
                              </Form.Item>
                            </Col>
                            {numberOfPendingAssignees > 0 && (
                              <Col
                                style={{
                                  display: 'inline-block',
                                  paddingRight: 0,
                                  paddingTop: 8,
                                  verticalAlign: 'center',
                                }}
                              >
                                <Text type="danger" style={{ marginRight: 4 }}>
                                  {t('new-task-form.pending')}
                                </Text>
                                <Badge count={numberOfPendingAssignees} />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label={t('new-task-form.company')}
                            name="company"
                            style={{ flex: 1, marginBottom: 14 }}
                          >
                            <Select
                              placeholder={t(
                                companiesOptions.length === 0
                                  ? 'new-task-form.add-new-company'
                                  : 'new-task-form.select-company',
                              )}
                              options={companiesOptions}
                              dropdownRender={(menu) => (
                                <SelectDropdown
                                  menu={menu}
                                  onAdd={onAddCompany}
                                  buttonText={t(
                                    'new-task-form.add-new-company',
                                  )}
                                />
                              )}
                              disabled={
                                !hasFieldPermission(
                                  TaskInternals.SUBMIT_TASK,
                                ) || isTemplate
                              }
                              onClick={() => {
                                getCompanies().then((data) => {
                                  const companyList = data.map((company) => {
                                    return {
                                      value: company.id,
                                      label: company.name,
                                      key: company.id,
                                    }
                                  })
                                  setCompaniesOptions(companyList)
                                })
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label={t('new-task-form.contact')}
                            name="contact"
                            style={{ flex: 1, marginBottom: 14 }}
                          >
                            <Select
                              placeholder={t(
                                contacts.length === 0
                                  ? 'new-task-form.add-new-contact'
                                  : 'new-task-form.select-contact',
                              )}
                              options={contactsOptions}
                              dropdownRender={(menu) => (
                                <SelectDropdown
                                  menu={menu}
                                  onAdd={onAddContact}
                                  buttonText={t(
                                    'new-task-form.add-new-contact',
                                  )}
                                />
                              )}
                              disabled={
                                !hasFieldPermission(
                                  TaskInternals.SUBMIT_TASK,
                                ) || isTemplate
                              }
                              onClick={() => {
                                getContacts().then((data) => {
                                  const contactList =
                                    data
                                      .map((contact) => {
                                        const hasFullName =
                                          contact?.firstName &&
                                          contact?.lastName

                                        let contactName = ''

                                        if (
                                          !hasFullName &&
                                          hasFullName !== null
                                        ) {
                                          contactName = `${contact?.firstName} ${contact?.lastName}`
                                        }
                                        return {
                                          value:
                                            contact?.contactEmail?.[0]?.email?.trim() ||
                                            '',
                                          label: (
                                            <SelectTag
                                              label={contactName}
                                              secondaryLabel={
                                                contact?.contactEmail?.[0]
                                                  ?.email
                                              }
                                            />
                                          ),
                                          key: contact.id,
                                          email:
                                            contact?.contactEmail?.[0]?.email?.trim() ||
                                            '',
                                          disabled: false,
                                          user: '',
                                          group: '',
                                          name: contactName.trim() || '',
                                          firstName: contact?.firstName || '',
                                          lastName: contact?.lastName || '',
                                          id: contact.id,
                                        }
                                      })
                                      .filter((item) => item?.email?.trim()) ||
                                    []
                                  setContactsOptions(contactList)
                                })
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Form.Item
                    label={
                      checkItemsCount !== 0
                        ? t('new-task-form.description-count', {
                            done: checkedItems,
                            count: checkItemsCount,
                          })
                        : t('new-task-form.description')
                    }
                    name="description"
                    style={{ marginBottom: 0 }}
                  >
                    <WYSIWYG
                      onChange={onEditorChange}
                      initialContent={
                        initialValues.taskDetails?.description || null
                      }
                      editorRef={editorRef}
                      contentPasteRef={contentPasteRef}
                    />
                  </Form.Item>
                  <Form.Item hidden name="importTasks" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name="excludeCompletedTasks"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                  {!isIssue && (
                    <Form.Item
                      label={t('new-task-form.sub-tasks')}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item name="subItems" noStyle>
                        <SubItems
                          value={subItems}
                          onChange={updateTaskLevel}
                          disabled={
                            !hasFieldPermission(TaskActions.ADD_CHILDREN)
                          }
                          setIsDirty={(value) => setIsSubItemDirty(value)}
                          importSubtasks={importSubtasks}
                          checkedItems={checkedItems}
                        />
                      </Form.Item>
                      {!!children.filter(
                        (child) => child.priorityGroup?.id === 8,
                      ).length && (
                        <>
                          <Typography.Title level={3}>Issues</Typography.Title>
                          <ul>
                            {children
                              .filter((child) => child.priorityGroup?.id === 8)
                              .map((child) => (
                                <li key={child.id}>
                                  <Link href={`/task/${child.id}`}>
                                    {child.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </>
                      )}
                      {hasSubItems && !isDocked ? (
                        <Link
                          onClick={() => {
                            openPlanViewer(initialValues)
                          }}
                        >
                          {t('new-task-form.children-link-text', {
                            count: children.length,
                          })}
                        </Link>
                      ) : initialValues?.children &&
                        initialValues.children.length > 0 &&
                        isDocked ? (
                        <Link
                          onClick={() => {
                            openPlanViewer(initialValues)
                          }}
                        >
                          {t('new-task-form.children-link-text', {
                            count: initialValues.children.length,
                          })}
                        </Link>
                      ) : null}
                    </Form.Item>
                  )}
                </>
              )}
            </AnimatedContainer>
            <Row
              style={{ marginBottom: 16, display: isUpdate ? 'none' : 'unset' }}
            >
              <Space
                direction="vertical"
                size={2}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setisAdvanced((isAdvanced) => !isAdvanced)
                  if (titleInputRef && titleInputRef.current) {
                    titleInputRef.current.focus()
                  }
                }}
              >
                <Text type="secondary" style={{ fontSize: 18 }}>
                  {isAdvanced
                    ? t('new-task-form.simple-mode')
                    : t('new-task-form.advanced-mode')}
                </Text>
                <AnimatePresence initial={false} mode="wait">
                  <motion.div
                    key={isAdvanced ? 'minus' : 'plus'}
                    initial={{
                      rotate: isAdvanced ? -90 : 90,
                    }}
                    animate={{
                      zIndex: 1,
                      rotate: 0,
                      transition: {
                        type: 'tween',
                        duration: 0.15,
                        ease: 'circOut',
                      },
                    }}
                    exit={{
                      zIndex: 0,
                      rotate: isAdvanced ? -90 : 90,
                      transition: {
                        type: 'tween',
                        duration: 0.15,
                        ease: 'circIn',
                      },
                    }}
                  >
                    {isAdvanced ? (
                      <UpOutlined
                        style={{ color: 'var(--secondary)', fontSize: 18 }}
                      />
                    ) : (
                      <DownOutlined
                        style={{ color: 'var(--secondary)', fontSize: 18 }}
                      />
                    )}
                  </motion.div>
                </AnimatePresence>
              </Space>
            </Row>
            <Row wrap>
              <Col md={10}>
                <Space
                  wrap
                  style={{
                    justifyContent: 'left',
                    width: '100%',
                    paddingBlock: 8,
                  }}
                >
                  {!isRecurring && (
                    <Form.Item name="dueDate" style={{ marginBottom: 0 }}>
                      <DueDateDropdown
                        allowClear={true}
                        outlined
                        disabled={!hasFieldPermission(TaskFields.DUE_AT)}
                      />
                    </Form.Item>
                  )}
                  {!isSubtaskOfRoutine && !isIssue && (
                    <Form.Item style={{ marginBottom: 0 }}>
                      <RoutineDropdown
                        outlined
                        title={t('new-task-form.recurrence.title')!}
                        onChange={(value) => handleRecurringChange(value)}
                        value={scheduledTask}
                        isOpen={routineIsOpen}
                        setIsOpen={setRoutineIsOpen}
                        flexibleTime={flexibleTime}
                        onSaveAgenda={setAgenda}
                        setFlexibleTime={setFlexibleTime}
                        iconStyles={isRecurringStyle}
                        disabled={
                          !hasFieldPermission(TaskFields.SCHEDULED_TASK)
                        }
                        isRoutineInstance={isRoutineInstanceSchedule(
                          initialValues,
                        )}
                      />
                    </Form.Item>
                  )}
                  {!isRecurring && !isIssue && (
                    <Form.Item style={{ marginBottom: 0 }}>
                      <SchedulingDropDown
                        outlined
                        title={t('new-task-form.recurrence.scheduling')!}
                        onChange={(value) => handleRecurringChange(value)}
                        value={scheduledTask}
                        setRoutineIsOpen={setRoutineIsOpen}
                        flexibleTime={flexibleTime}
                        setFlexibleTime={setFlexibleTime}
                        setAgenda={setAgenda}
                        showDateAfterIcon={true}
                        disabled={
                          !hasFieldPermission(TaskFields.SCHEDULED_TASK)
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item style={{ marginBottom: 0 }} hidden={!isUpdate}>
                    {!isTaskFlowViewerVisible && !isIssue && (
                      <Button
                        onClick={() => {
                          Userpilot.track('Clicked on Plan View button')
                          openPlanViewer(initialValues)
                        }}
                      >
                        <DeploymentUnitOutlined />
                        {t('new-task-form.plan-view')}
                      </Button>
                    )}
                  </Form.Item>
                </Space>
              </Col>
              {!isFloatButtonVisible && ActionButtons}
              {isOpen &&
                isFloatButtonVisible &&
                !isFloatButtonTemporarilyHidden &&
                !isDocked && (
                  <FloatingContainer
                    children={ActionButtons}
                    position={isDrawerOpen ? 'right' : 'center'}
                  />
                )}
            </Row>
          </Form>
        </Col>
        <DateSelectorModal
          isOpen={showDateSelector}
          onClose={() => setShowDateSelector(false)}
          onResolve={modalPromiseResolve}
          min={minDate}
        />
      </Row>
    )
  },
)

export default TaskEditForm
