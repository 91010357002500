import { camelize } from 'humps'
import { AgendaTemplate, IFormData } from 'types/Agendas'

type SegmentData = {
  rollCall: { email: string; isPresent: boolean }[]
  reviewLevel: {
    userId: string
    taskId: string
    title: string
    status: string
    createdAt: string
    levels: { id: string | number; title: string }[]
    groups: { id: string | number; title: string }[]
  }[]
  metrics: { taskId: string; userId: string }[]
  headlines: { title: string }[]
  issues: { priorityOrder: number; status: string; taskId: string }[]
  toDo: { taskId: string }[]
  meetingScore: { email: string; score: number; comments: string }[]
  meetingNotes: { note: string }[]
}

const DEFAULT_SEGMENT_DATA: SegmentData = {
  rollCall: [],
  reviewLevel: [],
  metrics: [],
  headlines: [],
  issues: [],
  toDo: [],
  meetingScore: [],
  meetingNotes: [],
}

export const generateAgenda = (
  data: AgendaTemplate,
  task: { id: string; title: string },
  attendees: { email?: string }[],
) => {
  const segments: IFormData[] = []

  data.segmentTemplates.forEach((segment) => {
    const segmentName = camelize(segment.name)

    const segmentContent =
      segmentName === 'rollCall'
        ? attendees.map((attendee) => ({
            email: attendee.email,
            isPresent: false,
          }))
        : DEFAULT_SEGMENT_DATA[segmentName as keyof SegmentData]

    const segmentData = {
      title: segment.segmentName,
      segmentTemplate: data.agendaTemplateId,
      segmentData: segmentContent,
      task: task,
      timeSpent: segment.defaultDuration,
      order: segment.order,
      name: segment.name,
    }

    segments.push(segmentData)
  })

  return segments
}

export const generateDefaultSegment = () => {}
