import { useEffect, useMemo, useState } from 'react'
import useStore from 'hooks/useStore'
import { FCProps } from 'types/FC'

export const Notifications = ({ children }: FCProps) => {
  const { hasUnreadNotifications, hasUnreadChats } = useStore((state) => state)
  const [prevUnread, setPrevUnread] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)

  useEffect(() => {
    const handleInteraction = () => setUserInteracted(true)

    document.addEventListener('click', handleInteraction, true)
    document.addEventListener('scroll', handleInteraction, true)
  }, [])

  const chime = useMemo(() => {
    return new Audio(`${window.location.origin}/chime.mp3`)
  }, [])

  useEffect(() => {
    const hasUnread = hasUnreadChats === true || hasUnreadNotifications === true
    const link = document.querySelector("link[rel~='icon']")
    if (hasUnread && prevUnread === false && userInteracted) {
      chime.play()
    }
    if (hasUnread === true) {
      // @ts-ignore
      link.href = `${window.location.origin}/favicon-badge.svg`
    } else {
      // @ts-ignore
      link.href = `${window.location.origin}/favicon.svg`
    }
    setPrevUnread(hasUnread)
  }, [
    hasUnreadChats,
    hasUnreadNotifications,
    chime,
    prevUnread,
    userInteracted,
  ])

  return <>{children}</>
}
