import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useEffect } from 'react'
import { TimeBlocking } from 'types/TimeBlocking'
import { recurRuleFromString } from 'utils/recurRule'

dayjs.extend(isBetween)

type TimeBlockingMonitorProps = {
  timeBlockingList: TimeBlocking[]
  onTriggerEvent: (value: TimeBlocking | null) => void
  intervalInSeconds?: number
}

const useTimeBlockingMonitor = ({
  timeBlockingList,
  onTriggerEvent,
  intervalInSeconds = 60,
}: TimeBlockingMonitorProps) => {
  useEffect(() => {
    const checkTimeBlocks = () => {
      const now = dayjs()
      const activeBlock = timeBlockingList.find((block) => {
        const startDate = dayjs(block.startDate)
        const endDate = dayjs(block.endDate)
        if (block.recurRule) {
          const rule = recurRuleFromString(block.recurRule)
          const occurrences = rule.between(
            now.startOf('day').toDate(),
            now.endOf('day').toDate(),
          )
          return occurrences.some((occurrence) => {
            const start = dayjs(occurrence).tz(block.timezone.code)
            const end = start.add(endDate.diff(startDate), 'millisecond')
            return now.isBetween(start, end)
          })
        } else {
          return now.isBetween(startDate, endDate)
        }
      })
      onTriggerEvent(activeBlock || null)
    }
    const interval = setInterval(checkTimeBlocks, intervalInSeconds * 1000)
    return () => clearInterval(interval)
  }, [intervalInSeconds, onTriggerEvent, timeBlockingList])
}

export default useTimeBlockingMonitor
