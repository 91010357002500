import { Switch } from 'antd'
import { useEffect } from 'react'
import useStore from 'hooks/useStore'
import { AppTheme } from 'types/Theme'
import { ReactComponent as LightIcon } from 'assets/sun.svg'
import { ReactComponent as DarkIcon } from 'assets/moon.svg'

const ThemeToggle = () => {
  const currentTheme = useStore((state) => state.theme)
  const toggleTheme = useStore((state) => state.toggleTheme)

  useEffect(() => {
    window.document.documentElement.setAttribute(
      'data-theme',
      AppTheme[currentTheme],
    )
  }, [currentTheme])

  return (
    <Switch
      onClick={() => {
        toggleTheme()
      }}
      checkedChildren={<LightIcon />}
      unCheckedChildren={<DarkIcon />}
      defaultChecked={currentTheme === AppTheme.LIGHT}
    />
  )
}

export default ThemeToggle
