import { AxiosResponse } from 'axios'
import { TimeBlocking, TimeBlockingBase } from 'types/TimeBlocking'
import type { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'

export interface TimeBlockingState extends ApiState {
  currentTimeBlocking: number
  timeBlocking: TimeBlocking[]
  createTimeBlocking: (
    timeBlocking: Partial<TimeBlockingBase>,
  ) => Promise<TimeBlocking>
  updateTimeBlocking: (
    timeBlocking: Partial<TimeBlockingBase>,
  ) => Promise<TimeBlocking>
  deleteTimeBlocking: (id: number) => Promise<void>
  getTimeBlocking: () => Promise<TimeBlocking[]>
  getTimeBlockingById: (id: number) => Promise<TimeBlockingBase>
  setCurrentTimeBlocking: (timeBlocking: number) => void
}

export const createTimeBlockingSlice: StateCreator<
  TimeBlockingState,
  [['zustand/immer', never]]
> = (set, get) => ({
  currentTimeBlocking: 0,
  timeBlocking: [],
  createTimeBlocking: async ({
    startDate,
    endDate,
    exDate,
    rDate,
    recurRule,
    duration,
    groupCategory,
    timezone,
  }) => {
    try {
      const response = await get().api!('POST', '/user/group/time-blocking', {
        startDate,
        endDate,
        exDate,
        rDate,
        recurRule,
        duration,
        groupCategory,
        timezone,
      })
      if (response?.status === 200 || response?.status === 201) {
        set((prev) => {
          prev.timeBlocking = prev.timeBlocking.concat(response.data)
        })
      }
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    }
  },
  updateTimeBlocking: async (timeBlocking) => {
    const response = await get().api!(
      'PATCH',
      `/user/group/time-blocking/${timeBlocking.id}`,
      timeBlocking,
    )
    return response?.data
  },
  deleteTimeBlocking: async (id: number) => {
    try {
      const response = await get().api!(
        'DELETE',
        `/user/group/time-blocking/${id}`,
      )

      if (response?.status >= 200 && response?.status < 300) {
        const timeBlocking = get().timeBlocking
        const index = timeBlocking.findIndex((cat) => cat.id === id)
        if (index !== -1) {
          timeBlocking.splice(index, 1)
          set((prev) => {
            prev.timeBlocking = timeBlocking
          })
        }
      }
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    }
  },
  getTimeBlocking: async () => {
    const response: AxiosResponse = await get().api!(
      'GET',
      `/user/group/time-blocking`,
    )
    const result: TimeBlocking[] = response?.data
    if (response.status === 200) {
      set((prev) => {
        prev.timeBlocking = result
      })
    }
    return result
  },
  getTimeBlockingById: async (id) => {
    const response: AxiosResponse = await get().api!(
      'GET',
      `/user/group/time-blocking/${id}`,
    )
    return response?.data
  },
  setCurrentTimeBlocking: (timeBlocking) => {
    set((draft) => {
      draft.currentTimeBlocking = timeBlocking
    })
  },
})
