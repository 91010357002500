import { Select, SelectProps, Tag } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import useStore from 'hooks/useStore'
import { isValidEmail } from 'utils/taskUtils'

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={!isValidEmail(label?.toString() || '') ? 'red' : undefined}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 2, marginBottom: 2 }}
    >
      {label}
    </Tag>
  )
}

type SelectMultipleContactsProps = {
  defaultValue?: string[]
  onChange?: (values: string[]) => void
  isInvalid?: boolean
  status?: '' | 'error' | 'warning'
}

const SelectMultipleContacts = ({
  defaultValue,
  onChange,
  isInvalid,
  status,
}: SelectMultipleContactsProps) => {
  const contacts = useStore((state) => state.contacts)
  const attendeesOptions: SelectProps['options'] = contacts.map((contact) => ({
    value: contact.contactEmail?.[0]?.email,
    label: contact.contactEmail?.[0]?.email,
  }))
  const style = { width: '100%', ...(isInvalid && { borderColor: 'red' }) }

  return (
    <Select
      mode="tags"
      tagRender={tagRender}
      allowClear
      status={status}
      style={style}
      placeholder="@john.doe, @jane.doe"
      defaultValue={defaultValue}
      onChange={onChange}
      options={attendeesOptions}
    />
  )
}

export default SelectMultipleContacts
