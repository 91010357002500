import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import type { Segment } from 'types/Agendas'
import SegmentItem from './SegmentItem'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { useNavigate } from 'react-router-dom'

type SegmentsProps = {
  data: Segment[] | undefined
}

const Segments = ({ data }: SegmentsProps) => {
  const { close } = useTaskEditor()
  const navigate = useNavigate()

  const [agendaSegments, setAgendaSegments] = useState<Segment[]>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setAgendaSegments(data || [])
  }, [data])

  const openDialog = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOk = () => {
    setOpen(false)
    close()
    navigate(`/agenda/meeting/${agendaSegments?.[0].task.id}`)
  }

  const launchAgenda = () => {
    const hasEmptySegmentData = (segments: Segment[] | undefined): boolean => {
      return (
        segments?.some(
          (segment) =>
            Array.isArray(segment.segmentData) &&
            segment.segmentData.length === 0,
        ) ?? false
      )
    }

    if (hasEmptySegmentData(agendaSegments)) {
      openDialog()
      return
    }

    close()
    navigate(`/agenda/meeting/${agendaSegments?.[0].task.id}`)
  }

  return (
    <Box p={4}>
      <Typography variant="h6" gutterBottom>
        Agenda
      </Typography>
      <List>
        {agendaSegments &&
          agendaSegments.map((item, index) => {
            if (item.title === 'Meeting Notes') {
              return (
                <Box p={2} key={index}>
                  <InputLabel sx={{ pb: 2 }}>Notes</InputLabel>
                  <TextField
                    multiline
                    minRows={4}
                    maxRows={8}
                    fullWidth
                    defaultValue={
                      'note' in item.segmentData[0]
                        ? item.segmentData[0].note
                        : ''
                    }
                    disabled={true}
                  />
                </Box>
              )
            }
            return <SegmentItem item={item} key={index} draggable />
          })}
      </List>
      <Box p={2} display="flex" gap={2}>
        <Button
          component="label"
          variant="contained"
          onClick={() => {
            close()
            navigate(`/agenda/editor/agenda/${agendaSegments?.[0].task.id}`)
          }}
        >
          Edit Agenda
        </Button>
        <Button component="label" variant="outlined" onClick={launchAgenda}>
          Launch Agenda
        </Button>
      </Box>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle id="alert-dialog-title">Launch Agenda?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to launch the agenda with empty segments?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOk} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Segments
