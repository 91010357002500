import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import type { Segment } from 'types/Agendas'
import AddAgenda from './AddAgenda'
import Segments from './Segments'

type AgendaItemProps = {
  data: Segment[] | undefined
}

const AgendaPreview = ({ data }: AgendaItemProps) => {
  const [agendaSegments, setAgendaSegments] = useState<Segment[]>()

  useEffect(() => {
    setAgendaSegments(data || [])
  }, [data])

  return (
    <Box>
      {agendaSegments ? <Segments data={agendaSegments} /> : <AddAgenda />}
    </Box>
  )
}

export default AgendaPreview
