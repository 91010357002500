import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useTaskEditor } from 'sections/TaskEditorModal'

const AddAgenda = () => {
  const { close } = useTaskEditor()
  const navigate = useNavigate()

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Agenda
      </Typography>
      <Box
        height={100}
        width={'100%'}
        my={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={4}
        p={2}
        sx={{ p: 2, border: '1px dashed grey' }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="subtitle1" gutterBottom>
            Create Agenda
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ width: 32, height: 32, minWidth: 32 }}
            onClick={() => {
              close()
              navigate('/agenda/designer')
            }}
          >
            <AddIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddAgenda
