import { RetweetOutlined } from '@ant-design/icons'
import {
  Button,
  DatePicker,
  DatePickerProps,
  Divider,
  InputNumber,
  Popconfirm,
  Select,
  Switch,
  TimePicker,
} from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import DayOfWeek from 'components/DayOfWeek'
import Dropdown from 'components/Dropdown'
import FuzzyTimeOptions from 'components/FuzzyTimeOptions'
import RangeOfRecurrence from 'components/RangeOfRecurrence'
import Row from 'components/Row'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Frequency } from 'rrule'
import { FlexibleTime } from 'types/FlexibleTime'
import { ScheduledTask } from 'types/Tasks'
import RecurRule, { recurRuleFromString } from 'utils/recurRule'
import { Endings, getDefaultByYearDay, getEndingFromRule } from 'utils/routines'
import DayOfMonth from './DayOfMonth'
import useStore from 'hooks/useStore'
import { Userpilot } from 'userpilot'
import SelectMultipleContacts from 'components/SelectMultipleContacts'
import { isValidEmail } from 'utils/taskUtils'
import { AgendaTemplate } from 'types/Agendas'

type CustomFrequencyPickerProps = {
  value?: Partial<ScheduledTask> | null
  onChange?: (value?: Partial<ScheduledTask> | null) => void
  title: string
  outlined?: boolean
  iconClassName?: string
  iconStyles?: React.CSSProperties
  setIsOpen: (nextValue: boolean) => void
  flexibleTime?: FlexibleTime
  setFlexibleTime: (f: FlexibleTime | undefined) => void
  isOpen: boolean
  disabled?: boolean
  isRoutineInstance: boolean
  onSaveAgenda?: (value: AgendaTemplate) => void
}

const RoutineDropdown = ({
  onChange: onSave,
  iconClassName,
  iconStyles,
  title,
  outlined,
  setIsOpen,
  flexibleTime,
  setFlexibleTime,
  isOpen = false,
  value: scheduledTask,
  disabled,
  isRoutineInstance,
  onSaveAgenda,
}: CustomFrequencyPickerProps) => {
  const { t } = useTranslation()
  const [isAllDay, setAllDay] = useState(false)
  const [interval, setInterval] = useState(1)
  const [count, setCount] = useState(1)
  const [ending, setEnding] = useState(Endings.NO_ENDING)
  const [frequency, setFrequency] = useState<Frequency>(Frequency.DAILY)
  const { localTimeZoneId, dateFormat, timeFormat } = useStore((state) => state)
  const [startTime, setStartTime] = useState(
    dayjs().minute(0).second(0).millisecond(0),
  )
  const [endTime, setEndTime] = useState(
    dayjs().minute(0).second(0).millisecond(0).add(1, 'hour'),
  )
  const [until, setUntil] = useState(
    dayjs().hour(23).minute(59).second(0).millisecond(0),
  )
  const [startDate, setStartDate] = useState(dayjs())
  const [byDay, setByDay] = useState<number[]>([])
  const [byMonthDay, setByMonthDay] = useState<number>(1)
  const [byYearDay, setByYearDate] = useState<Dayjs>(dayjs())
  const [currentAttendees, setCurrentAttendees] = useState<string[]>([])
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [isRoutinePopconfirmOpen, setIsRoutinePopconfirmOpen] = useState(false)
  const [templates, setTemplates] = useState<AgendaTemplate[]>([])
  const [isMeeting, setIsMeeting] = useState<boolean>(false)
  const [isTemplateError, setIsTemplateError] = useState<boolean>(false)
  const [isAttendeeError, setIsAttendeeError] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] =
    useState<AgendaTemplate | null>(null)

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().add(-1, 'day').endOf('day')
  }

  const getAgendaTemplates = useStore((state) => state.getAgendaTemplates)

  useEffect(() => {
    if (scheduledTask) {
      setAllDay(scheduledTask.allDay || false)
      if (scheduledTask.startDate) {
        setStartDate(dayjs(scheduledTask.startDate))
        setStartTime(dayjs(scheduledTask.startDate))
      }
      if (scheduledTask.endDate) {
        setEndTime(dayjs(scheduledTask.endDate))
      } else if (scheduledTask.startDate) {
        if (scheduledTask.duration) {
          setEndTime(
            dayjs(scheduledTask.startDate).add(
              scheduledTask.duration,
              'minute',
            ),
          )
        } else {
          setEndTime(dayjs(scheduledTask.startDate).add(1, 'hour'))
        }
      }
      const listAttendees = (scheduledTask.scheduledAttendees || []).map(
        (attendee) => attendee.email!,
      )
      const listPendings = (scheduledTask.pendingAttendees || []).map(
        (attendee) => attendee.email!,
      )
      setCurrentAttendees([...listAttendees, ...listPendings])
      const recurRule = scheduledTask.recurRule || ''
      if (recurRule) {
        const rRule = recurRuleFromString(recurRule)
        setEnding(getEndingFromRule(rRule))
        setByYearDate(getDefaultByYearDay(rRule))
        if (rRule.options.interval) {
          setInterval(rRule.options.interval)
        }
        if (rRule.options.count) {
          setCount(rRule.options.count)
        }
        if (rRule.options.freq) {
          setFrequency(rRule.options.freq)
        }
        if (rRule.options.byweekday) {
          setByDay(rRule.options.byweekday)
        }
        if (rRule.options.until) {
          setUntil(dayjs(rRule.options.until))
        }
        if (rRule.options.bymonthday && rRule.options.bymonthday.length > 0) {
          setByMonthDay(rRule.options.bymonthday[0])
        }
      }
    }
  }, [scheduledTask])

  const onByYearDayChange: DatePickerProps['onChange'] = (date, dateString) => {
    setByYearDate(date || dayjs())
  }

  const onStartDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    const verifiedDate = isAllDay
      ? dayjs(date.format('YYYY-MM-DD') + 'T00:00:00Z').utc()
      : date || dayjs()
    setStartDate(verifiedDate)
  }

  const handleRemove = () => {
    if (scheduledTask?.recurRule) {
      setIsRoutinePopconfirmOpen(true)
      return
    }
    removeRoutineDefinition()
  }

  const removeRoutineDefinition = () => {
    setIsOpen(false)
    onSave?.(null)
  }

  const handleSave = () => {
    if (isMeeting && !selectedTemplate) {
      setIsTemplateError(true)
      return
    }

    if (isMeeting && currentAttendees.length === 0) {
      setIsAttendeeError(true)
      return
    }

    const isScheduled = flexibleTime === FlexibleTime.SCHEDULED
    if (isScheduled && isInvalid) {
      return
    }
    if (isScheduled && endTime.isSame(startTime, 'minute')) {
      return
    }

    const startHour = startTime.hour()
    const startMinute = startTime.minute()
    const endHour = endTime.hour()
    const endMinute = endTime.minute()
    const startDateLocal = isScheduled
      ? startDate.hour(startHour).minute(startMinute).second(0).millisecond(0)
      : startDate.hour(0).minute(0).second(0).millisecond(0)

    const endDateLocal = isScheduled
      ? isAllDay
        ? startDateLocal.add(1, 'day').startOf('day')
        : startDateLocal
            .hour(endHour)
            .minute(endMinute)
            .second(0)
            .millisecond(0)
      : startDateLocal.add(15, 'minutes')

    const duration = isAllDay
      ? 1440
      : endDateLocal.diff(startDateLocal, 'minute')

    let bymonthday = undefined
    if (frequency === Frequency.MONTHLY) {
      bymonthday = [byMonthDay]
    }
    if (frequency === Frequency.YEARLY) {
      bymonthday = [byYearDay.date()]
    }
    const rule = new RecurRule({
      freq: frequency,
      interval: interval,
      dtstart: isAllDay ? startDate.toDate() : startDateLocal.toDate(),
      until:
        ending === Endings.END_BY
          ? isAllDay
            ? dayjs(until.format('YYYY-MM-DD') + 'T23:59:59Z')
                .utc()
                .toDate()
            : until.toDate()
          : undefined,
      count: ending === Endings.END_AFTER ? count : undefined,
      byweekday: frequency === Frequency.WEEKLY ? byDay : undefined,
      bymonth:
        frequency === Frequency.YEARLY ? [byYearDay.month() + 1] : undefined,
      bymonthday,
      flexible: flexibleTime,
    })

    const schedule = {
      id: scheduledTask?.id || undefined,
      initialDate: scheduledTask?.initialDate,
      startDate: isAllDay ? startDate.toDate() : startDateLocal.toDate(),
      endDate: isRoutineInstance ? endDateLocal.toISOString() : null,
      recurRule: isRoutineInstance ? undefined : rule.toString(),
      duration: isRoutineInstance ? undefined : duration,
      exDate: '',
      rDate: '',
      allDay: isAllDay,
      flexibleTime: flexibleTime,
      // Routine Definitions must keep the original timezone
      timezoneId: scheduledTask?.timezoneId || localTimeZoneId,
      scheduledAttendees: isScheduled
        ? currentAttendees.map((value) => ({ email: value }))
        : [],
      pendingAttendees: undefined,
    } as ScheduledTask

    onSave?.(schedule)
    setIsOpen(false)
    if (selectedTemplate) {
      onSaveAgenda?.(selectedTemplate)
    }
  }

  const getTemplateOptions = () => {
    getAgendaTemplates().then((data) => {
      // sort templates by id
      const sortedData = data.sort(
        (a, b) => a.agendaTemplateId - b.agendaTemplateId,
      )
      setTemplates(sortedData)
    })
  }

  const handleChangeAttendees = (value: string[]) => {
    const isInvalid = !!value.filter((attendee) => !isValidEmail(attendee))
      .length
    setIsInvalid(isInvalid)
    setCurrentAttendees(value)
  }

  const handleCreateMeeting = () => {
    getTemplateOptions()
    setIsMeeting((prevState) => !prevState)
  }

  const handleSelectedTemplate = (templateId: number) => {
    const template = templates.find(
      (template) => template.agendaTemplateId === templateId,
    )

    if (template) {
      setSelectedTemplate(template)
    } else {
      setSelectedTemplate(null)
    }
  }

  const updateStartTime = (value: Dayjs) => {
    if (endTime.isBefore(value, 'minute')) {
      setEndTime(value)
    }
    setStartTime(value)
  }

  const updateEndTime = (value: Dayjs) => {
    if (startTime.isAfter(value, 'minute')) {
      setStartTime(value)
    }
    setEndTime(value)
  }

  const removeRoutineDefinitionAnyway = () => {
    setIsRoutinePopconfirmOpen(false)
    removeRoutineDefinition()
  }

  const doNotRemoveRoutineDefinition = () => {
    setIsRoutinePopconfirmOpen(false)
  }

  const handleDropdownClose = (nextValue: boolean) => {
    if (isRoutinePopconfirmOpen) {
      // Handle issue with Dropdown being disposed before Delete Popup fires user-click
      setTimeout(() => {
        setIsOpen(nextValue)
        setIsRoutinePopconfirmOpen(false)
      }, 100)
    } else {
      setIsOpen(nextValue)
    }
  }

  return (
    <>
      <Dropdown
        title={title}
        icon={<RetweetOutlined className={iconClassName} style={iconStyles} />}
        onChange={(nextValue) => {
          Userpilot.track('Clicked Routine button')
          handleDropdownClose(nextValue)
        }}
        isOpen={isOpen}
        outlined={outlined}
        isReadonly={disabled}
      >
        <div style={{ width: '450px' }}>
          <Row style={{ padding: '0px 16px 0px 0px' }}>
            <h3 style={{ margin: '8px 16px' }}>{title}</h3>
            <span>
              {t('routines.flexible')}
              <Switch
                size="small"
                disabled={isAllDay}
                style={{ margin: '0px 8px' }}
                checked={flexibleTime === FlexibleTime.SCHEDULED}
                onChange={() =>
                  setFlexibleTime(
                    flexibleTime === FlexibleTime.SCHEDULED
                      ? FlexibleTime.BEGINNING
                      : FlexibleTime.SCHEDULED,
                  )
                }
              />
              {t('routines.scheduled')}
            </span>
          </Row>
          {flexibleTime !== FlexibleTime.SCHEDULED && (
            <Row style={{ padding: '0px 16px 8px 16px' }}>
              <FuzzyTimeOptions
                value={flexibleTime}
                onChange={(nextValue) => setFlexibleTime(nextValue)}
              />
            </Row>
          )}
          <Row style={{ padding: '8px 16px 8px 16px' }}>
            <>
              <span>
                {isRoutineInstance
                  ? t('routines.date')
                  : t('routines.start-date')}
                :{' '}
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={onStartDateChange}
                  defaultValue={startDate}
                  size="small"
                  disabled={isRoutineInstance}
                  format={dateFormat}
                />
              </span>
            </>
          </Row>
          <Row style={{ padding: '0px 16px 8px 16px' }}>
            {flexibleTime === FlexibleTime.SCHEDULED && (
              <>
                <span
                  style={{
                    display: isAllDay ? 'none' : 'flex',
                    flex: '1 0 auto',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('routines.start-time')}
                  <TimePicker
                    size="small"
                    value={startTime}
                    format={timeFormat}
                    onOk={(newDates) => {
                      updateStartTime(newDates)
                    }}
                    status={
                      endTime.isSame(startTime, 'minute') ? 'error' : undefined
                    }
                    style={{ paddingLeft: '8px' }}
                  />
                </span>
                <span
                  style={{
                    display: isAllDay ? 'none' : 'flex',
                    flex: '1 0 auto',
                    justifyContent: 'space-between',
                    paddingLeft: '8px',
                  }}
                >
                  {t('routines.end-time')}
                  <TimePicker
                    size="small"
                    value={endTime}
                    format={timeFormat}
                    onOk={(newDates) => {
                      updateEndTime(newDates)
                    }}
                    status={
                      endTime.isSame(startTime, 'minute') ? 'error' : undefined
                    }
                    style={{ paddingLeft: '8px' }}
                  />
                </span>
              </>
            )}
          </Row>
          {flexibleTime === FlexibleTime.SCHEDULED && (
            <Row style={{ padding: '0px 16px 8px 16px' }}>
              <span>{t('routines.attendees')}:</span>
              <SelectMultipleContacts
                defaultValue={currentAttendees}
                onChange={handleChangeAttendees}
                isInvalid={isInvalid}
                status={isAttendeeError ? 'error' : ''}
              />
            </Row>
          )}
          <Row style={{ padding: '0px 16px 8px 16px' }}>
            <span
              style={{
                display:
                  flexibleTime !== FlexibleTime.SCHEDULED
                    ? 'none'
                    : 'inline-block',
              }}
            >
              {t('new-task-form.recurrence.all-day')}
              <Switch
                size="small"
                checked={isAllDay}
                style={{
                  marginLeft: '8px',
                }}
                onChange={() => {
                  setStartDate(
                    dayjs(startDate.format('YYYY-MM-DD') + 'T00:00:00Z').utc(),
                  )
                  setStartTime(
                    dayjs(startTime.format('YYYY-MM-DD') + 'T00:00:00Z').utc(),
                  )
                  setAllDay((prevState) => !prevState)
                }}
              />
            </span>
          </Row>
          <Row style={{ padding: '0px 16px 16px' }}>
            <span style={{ fontWeight: 600 }}>
              {t('new-task-form.recurrence.repeat-every')}:
            </span>
            <InputNumber
              min={1}
              size="small"
              value={interval}
              style={{ width: '180px' }}
              onChange={(value) => setInterval(value as number)}
              disabled={isRoutineInstance}
              addonAfter={
                <Select
                  value={frequency}
                  disabled={isRoutineInstance}
                  style={{ width: '120px' }}
                  onChange={(value) => {
                    setFrequency(value)
                  }}
                  options={[
                    {
                      value: Frequency.DAILY,
                      label: t('new-task-form.recurrence.day'),
                    },
                    {
                      value: Frequency.WEEKLY,
                      label: t('new-task-form.recurrence.weeks'),
                    },
                    {
                      value: Frequency.MONTHLY,
                      label: t('new-task-form.recurrence.months'),
                    },
                    {
                      value: Frequency.YEARLY,
                      label: t('new-task-form.recurrence.years'),
                    },
                  ]}
                />
              }
            />
          </Row>
          <Row style={{ padding: '0px 16px 16px' }}>
            <span>
              Create A Meeting
              <Switch
                size="small"
                style={{ marginLeft: '8px' }}
                checked={isMeeting}
                onClick={handleCreateMeeting}
              />
            </span>
          </Row>
          <Row style={{ padding: '0px 16px' }}>
            <Select
              placeholder="Choose a template"
              style={{ width: 200 }}
              disabled={!isMeeting}
              allowClear
              status={isTemplateError && isMeeting ? 'error' : ''}
              onChange={handleSelectedTemplate}
              options={templates?.map((template) => {
                return {
                  value: template.agendaTemplateId,
                  label: template.templateName,
                }
              })}
            />
          </Row>
          <Row
            style={{
              padding: '8px 16px 16px 16px',
              justifyContent: 'flex-end',
            }}
          >
            {
              {
                [Frequency.YEARLY]: (
                  <>
                    <span>{t('routines.on')}: </span>
                    <DatePicker
                      onChange={onByYearDayChange}
                      defaultValue={byYearDay}
                      size="small"
                      disabled={isRoutineInstance}
                      format={dateFormat}
                    />
                  </>
                ),
                [Frequency.MONTHLY]: (
                  <DayOfMonth value={byMonthDay} onChange={setByMonthDay} />
                ),
                [Frequency.WEEKLY]: (
                  <DayOfWeek value={byDay} onChange={setByDay} />
                ),
                [Frequency.DAILY]: <></>,
                [Frequency.HOURLY]: <></>,
                [Frequency.MINUTELY]: <></>,
                [Frequency.SECONDLY]: <></>,
              }[frequency]
            }
          </Row>
          <Divider style={{ margin: '0px' }} />
          <RangeOfRecurrence
            ocurrences={count}
            setOcurrences={setCount}
            endRangeDate={until}
            setEndRangeDate={setUntil}
            ending={ending}
            setEnding={setEnding}
            disabled={isRoutineInstance}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '0px 16px 16px 8px',
            }}
          >
            {isRoutineInstance ? (
              <span style={{ marginRight: '8px' }}>
                * {t('routines.grayed-out-options')}
              </span>
            ) : (
              <Button
                type="default"
                onClick={handleRemove}
                style={{ marginRight: 8 }}
              >
                {t('actions.remove')}
              </Button>
            )}
            <Button type="primary" onClick={handleSave}>
              {t('actions.save')}
            </Button>
          </div>
        </div>
      </Dropdown>
      <Popconfirm
        title={t('routines.remove-recurrence-rule-title', {
          ns: 'validation',
        })}
        description={t('routines.remove-recurrence-rule-definition', {
          ns: 'validation',
        })}
        open={isOpen && isRoutinePopconfirmOpen}
        onConfirm={removeRoutineDefinitionAnyway}
        onCancel={doNotRemoveRoutineDefinition}
        okText={t('actions.yes')}
        cancelText={t('actions.no')}
      />
    </>
  )
}

export default RoutineDropdown
